import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
        <section className="footer-subscription">
            <div className='opening'>
              <table style={{ borderCollapse: "separate", borderSpacing: "10px" }}>
                <caption><h2>Nyitvatartás</h2></caption>
                <tbody>
                    <tr>
                        <td>Hétfő</td>
                        <td style= {{ color: "red", textAlign: "center" }}>Zárva</td>
                    </tr>
                    <tr>
                        <td>Kedd</td>
                        <td>7:00 – 18:00</td>
                    </tr>
                    <tr>
                        <td>Szerda</td>
                        <td>8:00 – 17:00</td>
                    </tr>
                    <tr>
                        <td>Csütörtök</td>
                        <td>7:00 – 18:00</td>
                    </tr>
                    <tr>
                        <td>Péntek</td>
                        <td>8:00 – 17:00</td>
                    </tr>
                    <tr>
                        <td>Szombat</td>
                        <td>6:00 – 12:00</td>
                    </tr>
                    <tr>
                        <td>Vasárnap</td>
                        <td style= {{ color: "red", textAlign: "center" }}>Zárva</td>
                    </tr>
                </tbody>
              </table>
            </div>
        </section>
        <div className="footer-links">
            <div className="footer-link-wrapper">
                <div className="footer-link-items">
                    <h2>Tippek / Trükkök</h2>
                    <a href='https://www.notino.hu/beautyblog/tippek-es-tanacsok/a-helyes-hajmosas-a-legjobb-fodrasz-tippek/'>A Helyes Hajmosás</a>
                    <a href='https://www.glamour.hu/szepseghirek/fenyes-puha-haj-tippek/vjvxx39'>Puha és Fényes Haj</a>
                    <a href='https://fodraszmester.com/kulonleges-melirtechnikak/'>Különleges Melírtechnikák</a>
                </div>
                <div className="footer-link-items">
                    <h2>Szolgáltatások</h2>
                    <a href='https://www.facebook.com/badicsneviki/videos/highlights-mel%C3%ADr-csak-mel%C3%ADrok/472647664233484/'>Hightlights Melírok</a>
                    <a href='https://www.facebook.com/100059594988259/videos/1049373089343958/?__so__=permalink'>Balayage</a>
                    <a href='https://www.facebook.com/100059594988259/videos/573062824250735?__so__=permalink'>Menyasszonyi frizura</a>
                </div>
            </div>
        </div>
        <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              <img src="images/viktoria-footer.png" alt="" width={150}/>
            </Link>
          </div>
          <small class='website-rights'>VHAIR © 2023</small>
          <div class='social-icons'>
            <a
              class='social-icon-link facebook'
              href='https://www.facebook.com/badicsneviki/'
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </a>
            <a
              class='social-icon-link instagram'
              href='https://www.instagram.com/fodrasz.viki/'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </a>
            <a
              class='social-icon-link email'
              href='mailto:badicsneviki@freemail.hu'
              target='_blank'
              aria-label='Email'
            >
              <i class="fa-solid fa-envelope"/>
            </a>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Footer