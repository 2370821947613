import React from "react";
import '../../App.css';
import { InstagramEmbed } from 'react-social-media-embed';

export default function Products(){
    return ( 
        <>
        <div className="gallery">
            <h1>Galéria</h1>
            <div className="insta-container">
                <div className="insta-wrapper">
                    <div className="instagram-post">
                        <InstagramEmbed url="https://www.instagram.com/p/Cu9vjIuojbB/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==" width={320}/>
                        <InstagramEmbed url="https://www.instagram.com/p/CuRpkD0ISqd/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==" width={320}/>
                        <InstagramEmbed url="https://www.instagram.com/p/CuSXBiAoUjH/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==" width={320}/>
                    </div>
                    <div className="instagram-post">
                        <InstagramEmbed url="https://www.instagram.com/p/CuHuC_sIvBz/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==" width={320}/>
                        <InstagramEmbed url="https://www.instagram.com/p/CbhRkSLoeEv/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==" width={320}/>
                        <InstagramEmbed url="https://www.instagram.com/p/CeJs5RsoaV1/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==" width={320}/>
                    </div>
                </div>
            </div>
        </div>
        </>
        
    );
}