import React from "react";
import '../../App.css';

export default function Services(){
    return (
    <div className="services">
        
        <div className="priceList">
            <h1>Árlista</h1>
            <img src="/images/pricelist.png" alt="pricelist"/>
            <h5>* Az árak tájékoztató jellegűek további információért érdeklődjön telefonon vagy valamelyik közösségi média felületen!</h5>
        </div>
    </div>
    
    )
}