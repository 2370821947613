import React from 'react';
import CardItem from './CardItem';
import './Cards.css';

function Cards() {
  return (
    <div className='cards'>
        <h1>Üdvözöllek a weboldalamon!</h1><br />
        <h2>Legyen szó női, férfi vagy gyermek frizurákról, igyekszem egyéniségedhez, kérésedhez igazodva számodra megfelelő frizurát elkészíteni.</h2>
        <div className="card__container">
            <div className="cards__wrapper">
                <ul className="cards__items">
                    <CardItem 
                    src="images/img-9.jpg"
                    text=""
                    label='Barna Balayage'
                    path="/regist"
                    />
                    <CardItem 
                    src="images/img-1.jpg"
                    text="Laza Elegancia és Stílus Varázslatos Kombinációja. Tökéletes Megjelenés "
                    label='Alkalmi frizurák'
                    path="/regist"
                    />
                </ul>
                <ul className="cards__items">
                    <CardItem 
                    src="images/img-2.jpg"
                    text="A Szerelem és Elegancia Pillanata. Varázslatosan Gyönyörű Frizurák a Nagy Napra."
                    label='Esküvői'
                    path="/regist"
                    />
                    <CardItem 
                    src="images/img-8.jpg"
                    text="Ragyogó Fények a Hajon. A Természetes Megvilágítás Varázsa."
                    label='Highlights melírok'
                    path="/regist"
                    />
                    <CardItem 
                    src="images/img-10.jpg"
                    text="Egyedi stílusú kombináció,
                    melegbarna alappal és finom bézsszőke melírokkal. Stílusos és
                    elegáns megjelenést kölcsönöz a hajnak."
                    label='Violamokka hajtő/bézsszőke melírok'
                    path="/regist"
                    />
                </ul>
            </div>
        </div>
    </div>
  )
}

export default Cards