import React from "react";
import "../../App.css";

export default function Regist() {
  return (
    <div className="regist-container">
      <div className="regist-word">
        <h1>Időpontfoglalás</h1>
        <div className="socialmedia-icons">
          <h4>Megtalálható vagyok különböző közösségi média felületeken:</h4>
          <div className="facebook-1">
            <a
              style={{ textDecoration: 'none' }}
              class="social-icon-links facebook-1"
              href="https://www.facebook.com/badicsneviki/"
              target="_blank"
              aria-label="Facebook"
            >
              <i class="fa-brands fa-facebook" />
              <h4 className="icons">Facebook</h4>
            </a>
            <div className="content"><h5>Üzenj nekem Facebookon! Nyugodtan írj Messengeren, örömmel válaszolok.</h5></div>
          </div>
          <div className="instagram-1">
            <a
              style={{ textDecoration: 'none' }}
              class="social-icon-links instagram-1"
              href="https://www.instagram.com/fodrasz.viki/"
              target="_blank"
              aria-label="Instagram"
            >
              <i class="fa-brands fa-square-instagram" />
              <h4 className="icons">Instagram</h4>
            </a>
            <div className="content"><h5>Instagramon is megtalálható vagyok aktuális képekért nézd meg az oldalamat!</h5></div>
          </div>
          <div className="phone-1">
            <a
              style={{ textDecoration: 'none' }}
              class='social-icon-links phone-1'
              href='tel:+36304140703'
              target='_blank'
              aria-label='Phone'
            >
              <i class='fa-solid fa-square-phone' />
              <h4 className="icons">Elérhetőség</h4>
            </a>
            <div className="content"><h5>Telefonon is elérhetsz a következő számon: +36-30-414-0703.</h5></div>
          </div>
        </div>
      </div>
      <iframe
      title="Google Maps"
      width="100%"
      height="400"
      frameBorder="0"
      style={{ border: 0 }}
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2701.726647010376!2d18.92099337503999!3d47.37825340371999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741e3fd534e8805%3A0xee3e340a08660c55!2sTincs%20fodr%C3%A1szat!5e0!3m2!1shu!2shu!4v1691080533372!5m2!1shu!2shu"
      allowFullScreen
    ></iframe>
    </div>
  );
}
